import DataFormatService from '@/services/DataFormatService'

export default class Product {
  constructor(item = {}) {
    return {
      name: item.name || '',
      quantity: item.quantity || '',
      sales_price: item.sales_price || '',
      management_fee: DataFormatService.formatPercentage(item.management_fee),
      gross_profit: item.gross_profit || '',
      description: item.description || '',
      price: item.price || '',
      vender_name: item.vender_name || '',
      vendor_cost: item.vendor_cost || '',
      line_Item_Number__c: item.line_Item_Number__c || '',
    }
  }
}
